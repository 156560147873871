<template>
  <AdminComp title="Admin Portal ICRS" collection="ICRS" />
</template>

<script>
import AdminComp from "@/components/adminComp";
export default {
  components: { AdminComp }
};
</script>
